import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/workpath0/src/components/layout.js";
import { graphql, Link } from "gatsby";
import Layout from '../../../../components/layout';
import SEO from '../../../../components/seo';
import { IntroProject, Intro, IntroImage } from '../../../../components/Intro/Intro';
import { Section, Container, CSSGrid, Col } from '../../../../components/Grid';
import { MainProject, CardStaticImg, CardLink, CardContainer, CardBody } from '../../../../components/Card';
import { ButtonGhost } from '../../../../components/Button';
import ImageWrap from '../../../../components/ImageWrap';
import { designTokens } from '../../../../components/Theme/designTokens';
import projects from '../../../../data/projects';
import { IconBox, IconCard } from '../../../../components/IconBox';
import { Box } from '../../../../components/Box';
import { Text } from '../../../../components/Text';
import { OutboundLink, StyledLink } from '../../../../components/StyledLink';
import { CallToAction } from '../../../../components/CallToAction';
import MdPerson from 'react-ionicons/lib/MdPerson';
import MdFlag from 'react-ionicons/lib/MdFlag';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEO title="Masonite Connected" mdxType="SEO" />
    <IntroProject projectName="Masonite Connected" projectDescription="An Internet of Things experience that connects a door to a user's smart home" projectYear="2020 - Present" projectImage="mc-preview" subProject="Masonite" subProjectLink="masonite" mdxType="IntroProject" />
    <Section mdxType="Section">
  <Container mdxType="Container">
    <CSSGrid mdxType="CSSGrid">
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
      <Col col={6 / 8} media={{
            phone: 1
          }} mdxType="Col">
        <h5>Background 📋</h5>
        <p>While there is a lot of innovation going on in the market around the front door, there is little innovation being introduced by door manufacturers.</p>
        <p>Video door bells and smart locks have experienced rapid growth and are expected to double within the next 5 years, driven by trends in connected home, IoT and growth of Airbnb and managing access. However, the products in the market today come with tedious workarounds and pain points.</p>
        <p><strong>Masonite has developed a product concept that it believes has solved several pain points in the market and differentiates it from competition.</strong> By providing a power to the door system, Masonite has developed a concept where technology can be integrated into a door with options for both a Masonite relationship (account, app) and 3rd party integration (Ring, Yale, etc).</p>
      </Col>
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
    </CSSGrid>
  </Container>
    </Section>
    <CSSGrid mdxType="CSSGrid">
  <Col mdxType="Col">
    <Box center bg={({
          theme
        }) => theme.greenTransparent} marginBottom={designTokens.space[6]} paddingY={designTokens.space[6]} mdxType="Box">
      <Container mdxType="Container">
        <CSSGrid mdxType="CSSGrid">
          <Col mdxType="Col">
            <Text center mdxType="Text">Problem:</Text>
            <p className="lead">Users need a way to manage and control a powered and connected door conveniently with their mobile phones and with their home automation applications.</p>
          </Col>
        </CSSGrid>
      </Container>
    </Box>
  </Col>
    </CSSGrid>
    <Section mdxType="Section">
  <Container mdxType="Container">
    <CSSGrid mdxType="CSSGrid">
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
      <Col col={6 / 8} media={{
            phone: 1
          }} mdxType="Col">
         <IconCard cardTitle="My Role" cardText="user research, wireframing, prototyping, interaction design, usability testing, visual design, front-end development" mdxType="IconCard">
          <MdPerson fontSize={designTokens.space[5]} color={designTokens.colors.base.grey0} mdxType="MdPerson" />
        </IconCard>
        <p>This project was complex, and as such, requires multiple teams to manage - we needed to build a door that is directly powered from a home, as well as have a mobile app & IoT infrastructure to support the embedded features.</p>
        <p>While also juggling a few other projects, <strong>I was also the sole designer</strong> on this new project - working between 3 software engineers, a hardware engineering team, a business & marketing team, and a handful of PM's managing different facets of this project.</p>
      </Col>
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
    </CSSGrid>
  </Container>
    </Section>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "48.75000000000001%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsTAAALEwEAmpwYAAABj0lEQVQoz22S6W6CUBCFef936GO49Je1FqOmaitQF7ARcQVlURBpwdM7k2C1hWTCncmdjzNnkKIoQh5hGPL7cDig0+mgVquh3+9jv99jt9tBUQZotVoYDocIggCn0+nak4f0FxjHMebzOQzDQKVSQbVaRbPZhCy/iPoEmtrDaPQG0/wU96kvvINKtzCK8/nMMKrlz8eHhna7LZQuEKGPFBYWlgnP8/6plPLkFjidGuJ8RHZJ4fseVFXFeDwW9THCs40odmCJKQqBt7Bf4BTBIWB15B95ZpqmAGu4ZMBXknJe5KOUgyg5Ho98gUZ2XY+BpEJRFKzXa/YSuHB9s9nAtu0rMGfcjUzbpa/quo40/eZG2i5t2rIsNBoNJEnCEfghZjOTJ7qd8DoyqSMghaZpWK1WyLKM/aOcVHe73euiwngrxp4JYFLsIQFJneu6/NvQ/0ZBqkjdZDJBuVzGcrmCbozw+PwAVXsXkBOLKFSYAx3HwXa7Fc1L9o7OFLIso1Qqof5UR+91wHBamu/7DCXGD69H61X7Z4h4AAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Preview",
          "title": "Preview",
          "src": "/static/e3d21801e7541c930d7d215c7c2aad55/d9199/mc-preview.png",
          "srcSet": ["/static/e3d21801e7541c930d7d215c7c2aad55/8ff5a/mc-preview.png 240w", "/static/e3d21801e7541c930d7d215c7c2aad55/e85cb/mc-preview.png 480w", "/static/e3d21801e7541c930d7d215c7c2aad55/d9199/mc-preview.png 960w", "/static/e3d21801e7541c930d7d215c7c2aad55/07a9c/mc-preview.png 1440w", "/static/e3d21801e7541c930d7d215c7c2aad55/29114/mc-preview.png 1920w", "/static/e3d21801e7541c930d7d215c7c2aad55/0d5b6/mc-preview.png 4674w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <Section mdxType="Section">
  <Container mdxType="Container">
    <CSSGrid mdxType="CSSGrid">
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
      <Col col={6 / 8} media={{
            phone: 1
          }} mdxType="Col">
        <h5>Discovery & Research 🕵️‍♀️</h5>
        <p>This project is complex and would require a heavy amount of research. We needed a research framework that would help us diagnose our users' needs and validate what we could breakout into build phases.</p>
        <p>With the success of the other projects utilizing UX research-backed process (<StyledLink underline="true" to={'/work/masonite/door-configurator'} mdxType="StyledLink">Door Configurator</StyledLink>, <StyledLink underline="true" to={'/work/masonite/advisar'} mdxType="StyledLink">Advisar</StyledLink>), we decided to start this project off using the <OutboundLink rel="noopener noreferrer" href="https://library.gv.com/gv-guide-to-uxresearch-for-startups-b6d0c8ac81b3" underline="true" target="_blank" mdxType="OutboundLink">Google Ventures' Research Process</OutboundLink> to help us validate our ideas.</p>
        <p>We began by drawing out our user journeys and personas, as well as mapping out which teams would need to be involved with the different ends of the product:</p>
      </Col>
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
    </CSSGrid>
  </Container>
    </Section>
    <Text center marginBottom={designTokens.space[3]} size={designTokens.fontSizes[0]} color={({
      theme
    }) => theme.grey500} mdxType="Text">A persona for our user: homeowners</Text>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "67.91666666666667%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAABCcAAAQnAEmzTo0AAACSUlEQVQ4y3WUSW/bMBCF9V/7g3poT70WvfZc5NJDE6SHAi0Ko6nt2Nm8aLcpURJFUvMyHAZBDCQCHobg8mn0ZsSE1H+QusLEiuM5qFlEtUuOL3UNtCueX8lYYrsm0rfEEWiWlGCsAdcAUwd4DVgFChoVYFi+57WBZVgjvON9cCxCfCgMaDLMaeaUqK7CfTrD4v4n5neXOLa5bLNKQ6cltO7Q9QO6rpejja5RVgXq+oCmaXhd02gdhQQoAHfFHN8u3uPT13c4u/yATTYT4Nh32O/3fLDG8XhEVVUM1RhHg4eHB1kL80odyRhLZJtgFyVptcLFny/48fszAz9im/+LGRqDu9tb5HnOh5SAh2HANHmJhtfHcQwizpyBbQRu8ytc3XxHXt/g7/IM680vAXrevNtusE9TgQVo3/d45YkeWh2BwbO0uOc3TxjGFkoXsqsdLOpGY2BIADnnRDGdE0UgF4vUgpKqqnF+foHZbMafYp4L148Om32KLWcZPNvtdpJlPI+X8QnYh9aixFqLtm3F4K7rxCPx0HmUZSlrXEmJ3vsT2ClwiMCRzQ1vDwoFMOydVJmBaZZxNXcCDi8MhXgT6E1o9ggMoKIopC2CT5IhZxPmsyzF4VBL24TieO+eoSceCnBFCdEEeqV0niezvMQ2ZM9e5kVscnqjykyU3zDxbsTE2XhnEcZRBt5yr/VNVKdgBwVvGtj+wKpgu5JVwOqMrE65Dbfxk12zhlNreLViXbOW8MegxZPmfHHM46UhF8eLy0PGc4payOXwCPI4Me2wYgHBAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Personas",
          "title": "Personas",
          "src": "/static/a4f93aebddbf735b72275c5eca9263e1/d9199/mc-persona.png",
          "srcSet": ["/static/a4f93aebddbf735b72275c5eca9263e1/8ff5a/mc-persona.png 240w", "/static/a4f93aebddbf735b72275c5eca9263e1/e85cb/mc-persona.png 480w", "/static/a4f93aebddbf735b72275c5eca9263e1/d9199/mc-persona.png 960w", "/static/a4f93aebddbf735b72275c5eca9263e1/07a9c/mc-persona.png 1440w", "/static/a4f93aebddbf735b72275c5eca9263e1/29114/mc-persona.png 1920w", "/static/a4f93aebddbf735b72275c5eca9263e1/9ab1f/mc-persona.png 2343w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "80%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAYAAAAWGF8bAAAACXBIWXMAABCcAAAQnAEmzTo0AAACQUlEQVQ4y4VUTa/TQAzM//9HHODIAYkDAqRXVWoBtTRN2jTJbvZ7bWzvJo+CBCuNtsnrG8/Y4zaH7h1+3r/F3e4Fh2HAZVlQa/1fGGOw73s8Ho8M2O/3eL1eoclxxhgUeu/pjhhjxJQS5pzlM8P7QO8y5gSEjCkmee+cEwEEUErxMzQfOsCX3mHyFvkw8e12Q/4Cn5wTzvqO2pB6O8rtg8E/Dqx3c9IZB5sQUpQ3XLXrOrGfM2BMAeflhpO6CkaCsRMmKgSQCcCA9W7OpxNeLhexwMdai23bbgpj8kI4614ItXmg81pgnRIHrGwjXKyjPzjpmRAQ8TRNolRaECz2j+/YDUfCAR/zBZUpLTBu3gira2iQiaC0oFapvSt2YvQ46U4UMhSpVctd1CVq01+W08cvuOwOaGLYLHMLOBKsMkMSAlakCGKdCqxFuCVPljFQczkq1TKTUJ4oClqeQ7R4I8vF9jcaSrsVWCz1Myw8nDplIqRUkfu8WWaraw5LT/2TKibjIoF66/xSrOconSpTHt9jO3zFmppNIVvnk2psmOj3aXN0QnRCXrUUQu6RZKqq5pXiHnIWy9YEHKbzK+YzTfonEXcSHU4B2YY6TF49Q2R+i3oIQdZpnTLHgm1Zr+pd8seR4c3hodB3Xocynt7A3H8C40Qi8D6SOqAfAHkmS0BDIfyAvt736QQUH6CQA7UAaBVhHXVTVMA2hHVTxnHcNoWtEgndBfyZB2RJJfcx1xUswS4R37AeKgD/PkRTrD793y86XtqRM2FpTQAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Personas",
          "title": "Personas",
          "src": "/static/0597fc6df7e45cf1fc1a07fb65f088c7/d9199/mc-persona-2.png",
          "srcSet": ["/static/0597fc6df7e45cf1fc1a07fb65f088c7/8ff5a/mc-persona-2.png 240w", "/static/0597fc6df7e45cf1fc1a07fb65f088c7/e85cb/mc-persona-2.png 480w", "/static/0597fc6df7e45cf1fc1a07fb65f088c7/d9199/mc-persona-2.png 960w", "/static/0597fc6df7e45cf1fc1a07fb65f088c7/07a9c/mc-persona-2.png 1440w", "/static/0597fc6df7e45cf1fc1a07fb65f088c7/29114/mc-persona-2.png 1920w", "/static/0597fc6df7e45cf1fc1a07fb65f088c7/6fc98/mc-persona-2.png 2161w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <Text center marginBottom={designTokens.space[3]} size={designTokens.fontSizes[0]} color={({
      theme
    }) => theme.grey500} mdxType="Text">User journey depicting a homeowner's experience from discovery to install to customer support</Text>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "76.25%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAALFAAACxQGJ1n/vAAACxklEQVQ4y0VT2XLcNhDc//+cPMSxY1f5MeU8WD5WslZSZO1BLi+QIImDADHtGVAVs6oXxKBn0NPD3b2Un3Eu7/FyOuB0ecLx/ICqOaJVBZruwusFdXvm2Cmjbjc0/QWNK1AL7IVad8XP8oZ2SpdYI+HH6R3uTn/i5vAHDsVbPNcf8V/9AYfze9wd3+D+/BaP5d/4cfwLh/IdF/8H9O8e9GmP9Ok74eYJg7rQru2PiDGinW9RjV9QqM+o9Bc043d0Zo96/Mb4CmXuoOwt2mmP1u6hx3sstw9Y7h4Rbh8oPpzQqSPtru0znPWIgeBdhB4mLD7C2iUrl3czO740ISwrc0LmEQC3BsS0YgWR5feml4LNM5ZlYV8KvqFiz0oMuoEarjCuh54aLOvI+wLDWDFqGD+wwhq27zBXDXRbUQwGajzRrhtOkOtSoowQAtaVb2VFxHFZ5WEhHGeVkZWLsjUyAl/m4RYngqF0QbtGiYcBiRITFk6wXHhFWB3H1hyLnJTjrxxZBSGaDD4nYq7SPJSyfmKfuGCKWMWPVRyh34UjF1stfBizohBdVrgyf4lzji/RUKK4FeQfJsScLLdIMYG0T6yC2PLNkpTjeSMWZLWeC4vi+NoyF6zVy/8Jfa8wTVP2TjDPM9q2zb7K471H13XMG7KfkjPogTkNyVfQj9nDraB8i2V55QS1qWSFxhievOKvYCtorYXivdY684U3soBWdSRDywplKKw+H3o/sxqX1UnLEPhlazFt0xZvxZqt7Y1DdptyN5yzQo6AeCDkzEyLd8TJGcF7cnokHlSGc56mWZMR3hI4J3GOIdMPfE7UaS5YtT9zy8ZYXC4lt13lby+EzYLzuWCPOohAM1tud8LIkHPpTPaDHvO/aJiu2BXNITk/p3HqUqeuqeurNNs+jXOX2rz/HXPLmKwfmb+txunXd518MOnaPqZfD6d/wnVj2BkAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "User Journey",
          "title": "User Journey",
          "src": "/static/475711acc587a03a00d3934c1cc83d65/d9199/mc-journey.png",
          "srcSet": ["/static/475711acc587a03a00d3934c1cc83d65/8ff5a/mc-journey.png 240w", "/static/475711acc587a03a00d3934c1cc83d65/e85cb/mc-journey.png 480w", "/static/475711acc587a03a00d3934c1cc83d65/d9199/mc-journey.png 960w", "/static/475711acc587a03a00d3934c1cc83d65/07a9c/mc-journey.png 1440w", "/static/475711acc587a03a00d3934c1cc83d65/e216b/mc-journey.png 1904w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <Text center marginBottom={designTokens.space[3]} size={designTokens.fontSizes[0]} color={({
      theme
    }) => theme.grey500} mdxType="Text">Blueprint for how our customer support would interact with a homeowner</Text>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "95.41666666666667%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAATCAYAAACQjC21AAAACXBIWXMAABCcAAAQnAEmzTo0AAACwElEQVQ4y51U2W7bMBDU/39SH/vYhwLtSxAgh4tElq2bukhdnM7QkuIUKApUAE2T3B3Ozu4yei++4uHlCx4fH1GWJfq+R9d1fx3DMCBNUzw/P+P19RWn00mzf3p6wvV69dG5aZDUJWzfwTqHeZ7/ORztBB4uvxHwWltrffQtA35cRyy2R8/bvff4j2938tHkLPwyHyfLOsOObbARtl8csNqbNTfW3nLpAsK6rmjcQh+vT+c+MqZBVddYlgXTNIVwsizlPAaQtm2pzQXjOIV1RZ2pFSaGLtBzckFjzMaQgNLNGEOHMdwojao6R9NWWP1MfWok8S+4oaH9hLosYNsOnhcs04gLE1S3vZ9WshdDComiKILI87xgXujUvYXRu4Ksc8DFmLsTlpHMLmeMDy+YTjEm+j0UA75frP95HVC7xUfYtDiSsU3jUh+6iqnb12JCXce5DrbGrRyd71UBpBi1pK+6knY38IWl0KA0MWuuC4zbrgprxwS60aLh2vRXklgxcV03sXe3RDLLk/uUf7GVphqSQclJKHyWZSFp2o/f4zDry/Nc534j5KOuNxBLZXkHbFjsu4P2pbH29Elzgatj9irI88wrmQGwZ4cUeXEYCCClgwDFSPtVVfH/rVb3y0KN8qdmyalDjsIWspy2G8Kci4FtGW4PO1i2WMviHYWAga3m3MBIWGa8XCEbU/qeuofCVoF2pP05ywvclBHcbJcYDO6KdR63KBp2U0pAFjsTM1hpWIbmioauR8HqlxYKVwyzPGPYCfIiDf3dsrhNUzLjcwg5zS7hbLc9X958WRUKgAwJqG7ZO0XzOT4jOSfhklvrpTC1Ca+LzqWtNP1IYOM1kxAL+4/XRUbSVM5irKwmSRLeyl3n44n58P1Iit8O7sf9J0A9Bhpi8aft/socr80OcD/fA4uxQhQ7Mb6/8M7uYPgb1r7F2lO5pgMAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Experience",
          "title": "Experience",
          "src": "/static/b5b304c34ed13313afd5ee5df9ab499a/d9199/mc-service-blueprint.png",
          "srcSet": ["/static/b5b304c34ed13313afd5ee5df9ab499a/8ff5a/mc-service-blueprint.png 240w", "/static/b5b304c34ed13313afd5ee5df9ab499a/e85cb/mc-service-blueprint.png 480w", "/static/b5b304c34ed13313afd5ee5df9ab499a/d9199/mc-service-blueprint.png 960w", "/static/b5b304c34ed13313afd5ee5df9ab499a/07a9c/mc-service-blueprint.png 1440w", "/static/b5b304c34ed13313afd5ee5df9ab499a/29114/mc-service-blueprint.png 1920w", "/static/b5b304c34ed13313afd5ee5df9ab499a/6fc98/mc-service-blueprint.png 2161w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "62.5%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAABCcAAAQnAEmzTo0AAABnUlEQVQ4y4VSTVPCMBDt//ef+Ae8ON7k4sHhpM4IVApS+klp0zRNm+duIFAKambSbTa7L2/frofBMsZYq7XGZrNBkiRkQ2zDFHEc0Y6tz8U5O1zeLcCu65DnGaqqRpyuMVtNUOz25MtRFMXfgMNLt4dLqAhB+nyVOI531vvtJfaVZQnfXyDLcijVoO97suoYb64qu2SoWpisQEfntm1tECfv9xVEqaj8yvpYX96EDS1D9Lq20CeG7qcPQqjHCVoCYQ2NBe6gdIFUvpKvp7NynOy3Cu6hxcLRtDm25F53SIMVGirN1PLwAFGoRYPPrymepnfU6RhNc7iTUtqmtXKHssiQpqllfdKQk7/DEFVdo8dZ5KoS+Jy94eP9BcvAJ/atvWOA5XJpy95GEebzmZXkoikNvcpOLvksNGlGX1+YE2terDE3jC3rLIQ4Mxx32Onn1rrUePAlGm0uYhzAeDK88Uy57nJClGSYr7dIdiUWZNnPLBnwVt7VHJ46fkxaBQGUJF07DX8xt9oNgW7NsDceznPAYR47ArcaH1n/B/gDOe35B+IBn/sAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Experience",
          "title": "Experience",
          "src": "/static/4ec23ecc9f6aa4db61472254d1731399/d9199/mc-e2e-journey.png",
          "srcSet": ["/static/4ec23ecc9f6aa4db61472254d1731399/8ff5a/mc-e2e-journey.png 240w", "/static/4ec23ecc9f6aa4db61472254d1731399/e85cb/mc-e2e-journey.png 480w", "/static/4ec23ecc9f6aa4db61472254d1731399/d9199/mc-e2e-journey.png 960w", "/static/4ec23ecc9f6aa4db61472254d1731399/07a9c/mc-e2e-journey.png 1440w", "/static/4ec23ecc9f6aa4db61472254d1731399/29114/mc-e2e-journey.png 1920w", "/static/4ec23ecc9f6aa4db61472254d1731399/6fc98/mc-e2e-journey.png 2161w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <Section mdxType="Section">
  <Container mdxType="Container">
    <CSSGrid mdxType="CSSGrid">
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
      <Col col={6 / 8} media={{
            phone: 1
          }} mdxType="Col">
        <h5>Goals 🎯</h5>
        <p>If users preferred using the app instead of using a home automation app to control the features in the powered door, we wanted the experience to be transient and quick - not wanting a user to have to dig around to perform a simple task. Our higher-level goals for the project at-large were:</p>
        <Box marginBottom={designTokens.space[3]} bg={({
              theme
            }) => theme.greenTransparent} mdxType="Box">
          <p style={{
                marginBottom: '0'
              }}>To understand how different types of users control (and would control) their smart home devices connected to their door (e.g. smart locks, doorbells, etc.).</p>
        </Box>
        <Box marginBottom={designTokens.space[3]} bg={({
              theme
            }) => theme.greenTransparent} mdxType="Box">
          <p style={{
                marginBottom: '0'
              }}>To understand the demographic of users who currently own and don’t own a Masonite Door.</p>
        </Box>
        <Box marginBottom={designTokens.space[3]} bg={({
              theme
            }) => theme.greenTransparent} mdxType="Box">
          <p style={{
                marginBottom: '0'
              }}>Change the way consumers think about doors</p>
        </Box>
        <Box marginBottom={designTokens.space[3]} bg={({
              theme
            }) => theme.greenTransparent} mdxType="Box">
          <p style={{
                marginBottom: '0'
              }}>Make discovering, purchasing, installing, and using a Masonite Connected door as simple as possible for users</p>
        </Box>
      </Col>
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
    </CSSGrid>
  </Container>
    </Section>
    <Section mdxType="Section">
  <Container mdxType="Container">
    <CSSGrid mdxType="CSSGrid">
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
      <Col col={6 / 8} media={{
            phone: 1
          }} mdxType="Col">
        <p>We conducted remote market research interviews to help gauge perceptions and pain points of users who were in our demographic. Our research methods included:</p>
        <CSSGrid mdxType="CSSGrid">
          <Col col={1 / 3} media={{
                phone: 1
              }} mdxType="Col">
            <CardContainer mdxType="CardContainer">
              <CardBody mdxType="CardBody">
                <Text center size={designTokens.fontSizes[1]} mdxType="Text">Surveys</Text>
              </CardBody>
            </CardContainer>
          </Col>
          <Col col={1 / 3} media={{
                phone: 1
              }} mdxType="Col">
            <CardContainer mdxType="CardContainer">
              <CardBody mdxType="CardBody">
                <Text center size={designTokens.fontSizes[1]} mdxType="Text">Interviews</Text>
              </CardBody>
            </CardContainer>
          </Col>
          <Col col={1 / 3} media={{
                phone: 1
              }} mdxType="Col">
            <CardContainer mdxType="CardContainer">
              <CardBody mdxType="CardBody">
                <Text center size={designTokens.fontSizes[1]} mdxType="Text">Observations</Text>
              </CardBody>
            </CardContainer>
          </Col>
        </CSSGrid>
        <p>After intial phases of the prototype, we went back and conducted user testing using Figma prototypes. Our research brought a few insights and impacts to light for us:</p>
        <h6>Insights and Impacts</h6>
        <Box marginBottom={designTokens.space[3]} mdxType="Box">
          <Text bold marginBottom={designTokens.space[2]} size={designTokens.fontSizes[0]} color={({
                theme
              }) => theme.blue} mdxType="Text">Insight</Text>
          <Text size={designTokens.fontSizes[1]} marginBottom={designTokens.space[2]} mdxType="Text">Automation is one of the most appealing aspects of smart home tech.</Text>
          <Text bold marginBottom={designTokens.space[2]} size={designTokens.fontSizes[0]} color={({
                theme
              }) => theme.green} mdxType="Text">Impact</Text>
          <Text size={designTokens.fontSizes[1]} mdxType="Text">We need to design an app that prioritizes the ability of this product to automatically work and convey that it is automatically working.</Text>
        </Box>
        <Box marginBottom={designTokens.space[3]} mdxType="Box">
          <Text bold marginBottom={designTokens.space[2]} size={designTokens.fontSizes[0]} color={({
                theme
              }) => theme.blue} mdxType="Text">Insight</Text>
          <Text size={designTokens.fontSizes[1]} marginBottom={designTokens.space[2]} mdxType="Text">The vast majority of smart home tech users interact with the mobile app in order to do something (rather than passive viewing of information).</Text>
          <Text bold marginBottom={designTokens.space[2]} size={designTokens.fontSizes[0]} color={({
                theme
              }) => theme.green} mdxType="Text">Impact</Text>
          <Text size={designTokens.fontSizes[1]} mdxType="Text">Make sure that active interactions are easy to find and intuitive to use.</Text>
        </Box>
        <Box marginBottom={designTokens.space[3]} mdxType="Box">
          <Text bold marginBottom={designTokens.space[2]} size={designTokens.fontSizes[0]} color={({
                theme
              }) => theme.blue} mdxType="Text">Insight</Text>
          <Text size={designTokens.fontSizes[1]} marginBottom={designTokens.space[2]} mdxType="Text">Smart home tech users tend to own multiple smart products with the expectation that they play well together.</Text>
          <Text bold marginBottom={designTokens.space[2]} size={designTokens.fontSizes[0]} color={({
                theme
              }) => theme.green} mdxType="Text">Impact</Text>
          <Text size={designTokens.fontSizes[1]} mdxType="Text">Design for more than just mobile; design for IoT integration.</Text>
        </Box>
        <Box marginBottom={designTokens.space[3]} mdxType="Box">
          <Text bold marginBottom={designTokens.space[2]} size={designTokens.fontSizes[0]} color={({
                theme
              }) => theme.blue} mdxType="Text">Insight</Text>
          <Text size={designTokens.fontSizes[1]} marginBottom={designTokens.space[2]} mdxType="Text">Make it so that a connected door can be geo-located</Text>
          <Text bold marginBottom={designTokens.space[2]} size={designTokens.fontSizes[0]} color={({
                theme
              }) => theme.green} mdxType="Text">Impact</Text>
          <Text size={designTokens.fontSizes[1]} mdxType="Text">Masonite's first touch with the consumer - knowing exactly where our consumers are located and how we can best target untapped markets</Text>
        </Box>
      </Col>
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
    </CSSGrid>
  </Container>
    </Section>
    <Section mdxType="Section">
  <Container mdxType="Container">
    <CSSGrid mdxType="CSSGrid">
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
      <Col col={6 / 8} media={{
            phone: 1
          }} mdxType="Col">
        <h5>Features ✨</h5>
        <p>To help streamline our process, we wanted to build a rough guideline that each feature would adhere to - for the users of this product, each feature should be:</p>
        <CSSGrid mdxType="CSSGrid">
          <Col col={1 / 3} media={{
                phone: 1
              }} mdxType="Col">
            <CardContainer mdxType="CardContainer">
              <CardBody mdxType="CardBody">
                <Text center size={designTokens.fontSizes[1]} mdxType="Text">Personalized</Text>
              </CardBody>
            </CardContainer>
          </Col>
          <Col col={1 / 3} media={{
                phone: 1
              }} mdxType="Col">
            <CardContainer mdxType="CardContainer">
              <CardBody mdxType="CardBody">
                <Text center size={designTokens.fontSizes[1]} mdxType="Text">Simple</Text>
              </CardBody>
            </CardContainer>
          </Col>
          <Col col={1 / 3} media={{
                phone: 1
              }} mdxType="Col">
            <CardContainer mdxType="CardContainer">
              <CardBody mdxType="CardBody">
                <Text center size={designTokens.fontSizes[1]} mdxType="Text">Useful</Text>
              </CardBody>
            </CardContainer>
          </Col>
        </CSSGrid>
        <p>Based off of our research and current manufacturing constraints, we began seeing what features we could add to V1 of this product:</p>
        <Box marginBottom={designTokens.space[3]} bg={({
              theme
            }) => theme.blueTransparent} mdxType="Box">
          <p style={{
                marginBottom: '0'
              }}>Battery-backup in case power is lost from the source of the home</p>
        </Box>
        <Box marginBottom={designTokens.space[3]} bg={({
              theme
            }) => theme.blueTransparent} mdxType="Box">
          <p style={{
                marginBottom: '0'
              }}>Motion-activated LED lighting in areas around the door, providing accent lighting and the ability to see the key hole in the dark, which can also be managed on a Masonite app</p>
        </Box>
        <Box marginBottom={designTokens.space[3]} bg={({
              theme
            }) => theme.blueTransparent} mdxType="Box">
          <p style={{
                marginBottom: '0'
              }}>Integrated design of powered smart locks and video door bells that no longer require changing of batteries and integrate into the 3rd party ecosystems</p>
        </Box>
        <Box marginBottom={designTokens.space[3]} bg={({
              theme
            }) => theme.blueTransparent} mdxType="Box">
          <p style={{
                marginBottom: '0'
              }}>Ability to remotely know if your door is open or closed</p>
        </Box>
        <Box marginBottom={designTokens.space[3]} bg={({
              theme
            }) => theme.blueTransparent} mdxType="Box">
          <p style={{
                marginBottom: '0'
              }}>Future features include smart glass which provides natural light at all times and privacy at the touch of a button (or motion-activated), fire/smoke detection, & interior door line expansion</p>
        </Box>
      </Col>
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
    </CSSGrid>
  </Container>
    </Section>
    <Text center marginBottom={designTokens.space[3]} size={designTokens.fontSizes[0]} color={({
      theme
    }) => theme.grey500} mdxType="Text">Mapping out information will pass through each connected device</Text>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "80%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAYAAAAWGF8bAAAACXBIWXMAACE4AAAhOAFFljFgAAACBElEQVQ4y5WU247aMBCGefk+wfYVVtpK2xvaq94WCaGqNymVVqtdcRALmwNxzrbjw99xIIQQqFpLo8Ea+/P8MxNGuLGstVf2B7uMnZ8fXQu0QeeNMdDOpMAi5vi24uAVh5A5xQoYK8nM6fzo2iutr+saUkpI8kpweG8xHuYRijxHUsZ4YVsExQaijjrgOeQ8O7c4F6e9EAKhH2C9XIKxGNZYMAmssgpax/0ML2viZDrLKROXoTG6KV8pfXLqKMfQXmPJclLi5Nthhi3ISS3LEnd3HzGbzZq41gpJtYDS8lgOBVVLPNzfYzptz+gO6ECcVw3MWRiGGI+/wPO8Qx1VRhkFJzXucppmeHz8jMlk0txxjFGrVmsLKfQJzhjDPg4QRQE9xAeNcxLzPMPr4hnb3Y7uik6yUgZp4cOPnxsp7nW3crGhkRCDpnU1r7Hnb0gVzrusISqL36sxvj99QJYWqJWk2ZME3JFXV4EHpEJYRAgq3e+y6+Z8/osaMIVPo+EKX9FsiZoNQedAS8AyRMRNfw5dBwVXKPP6WFyLf1umAYZ9oAEpxI79xNP7JwKTXF1TkAbWVAS/ZZxGMce+osZx28+wLARe1j/gvX5FmiRIqKvrLEJUUn1u2p7iIbZF2Qe6H1mWYrXcYLOm12j+CmngV0BCmTPxFzvGlbHDb/niP+c/rZvPPxpQ3PJg8uY/AAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Architecture",
          "title": "Architecture",
          "src": "/static/13e7dfeeb7d4b48d44cf3eefe8d449a0/d9199/mc-architecture-diagram.png",
          "srcSet": ["/static/13e7dfeeb7d4b48d44cf3eefe8d449a0/8ff5a/mc-architecture-diagram.png 240w", "/static/13e7dfeeb7d4b48d44cf3eefe8d449a0/e85cb/mc-architecture-diagram.png 480w", "/static/13e7dfeeb7d4b48d44cf3eefe8d449a0/d9199/mc-architecture-diagram.png 960w", "/static/13e7dfeeb7d4b48d44cf3eefe8d449a0/07a9c/mc-architecture-diagram.png 1440w", "/static/13e7dfeeb7d4b48d44cf3eefe8d449a0/29114/mc-architecture-diagram.png 1920w", "/static/13e7dfeeb7d4b48d44cf3eefe8d449a0/92c75/mc-architecture-diagram.png 2499w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <Section mdxType="Section">
  <Container mdxType="Container">
    <CSSGrid mdxType="CSSGrid">
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
      <Col col={6 / 8} media={{
            phone: 1
          }} mdxType="Col">
        <h5>Design System 🎨</h5>
        <p>Since we're a small team (1 designer, 3 software engineers) and we're working on other projects in tandem, I needed to come up with a way of designing and documenting patterns rather than one-off prototypes of repetitive interactions.</p>
        <p>We wanted to get up and running with a prototype quickly, so we used Material V2 as a foundation for our UI - making slight changes to help provide a better experience for this product.</p>
        <p>To help keep everything under a single, cross-platform design system, we abstracted styles (colors, typography, spacing) to feed into platform specfic components - and sticking to a few design system goals:</p>
        <CSSGrid mdxType="CSSGrid">
          <Col col={1 / 3} media={{
                phone: 1
              }} mdxType="Col">
            <Box center bg={({
                  theme
                }) => theme.blueTransparent} marginBottom={designTokens.space[3]} mdxType="Box">
              <p style={{
                    marginBottom: '0'
                  }}>Foster platform familiarity</p>
            </Box>
          </Col>
          <Col col={1 / 3} media={{
                phone: 1
              }} mdxType="Col">
            <Box center bg={({
                  theme
                }) => theme.pinkTransparent} marginBottom={designTokens.space[3]} mdxType="Box">
              <p style={{
                    marginBottom: '0'
                  }}>Document as much as possible</p>
            </Box>
          </Col>
          <Col col={1 / 3} media={{
                phone: 1
              }} mdxType="Col">
            <Box center bg={({
                  theme
                }) => theme.greenTransparent} marginBottom={designTokens.space[3]} mdxType="Box">
              <p style={{
                    marginBottom: '0'
                  }}>Build components around use cases</p>
            </Box>
          </Col>
        </CSSGrid>
        <p>We also made sure our design system and components were built to accommodate scalibility, so components can work well alongside each other, at the same time, render well on various device resolutions.</p>
      </Col>
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
    </CSSGrid>
  </Container>
    </Section>
    <Text center marginBottom={designTokens.space[3]} size={designTokens.fontSizes[0]} color={({
      theme
    }) => theme.grey500} mdxType="Text">Mobile Design System using Material V2</Text>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "62.5%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAAsTAAALEwEAmpwYAAACrklEQVQ4y01TO08UURidXqLQuLsSIw8jGFELY2GBEenUitb4iIm9lRG1M8bEf0CwwMKCxmdB0MQYAxh5BGJAg8KyPJaVBXZ33jP3scfvfjMQ7+RmZr6598w53znXamlpRWNjIz6OvIUZxWIRQRBASgUhBGIhuT47PYb+/scQEbiutaZqnS4aWkAJD5peLAN4oOEgenu6YVfLUEozgFIKcRzzRkUrdRzgy9d3WN+Zgl1z4Pser6vXU1DpIfBsWG1t7chksjQzeDU0wIuElAwUM0PDRnE9iBYxkx9klpVKhZkmoGZq6HAX1pHmHHK5HDo7T+DSxQvYKq6Q5BBxFPHiiFiaHygjkTaubcxifG4YUSioLYIZMqIB1hJW06EMus7l0N3TheZsK549ebgvmdlSD6VMphnVyjK+zQ7zs+/7/7FMQK22jsPoPN2Mm/c60HvlFNpbj2Ny4jN/NCBmYUhsuZemvyQNZEBlZw01z0NIBiYGJaDWybNZPBo8hhv3s+jpa8Kt21cxPzMGrSKWvsc2imK+azJIKQ/fRz5gZ3EJoTEvbQ8Dnu8+irtPG9B3/QxGRgeo4S6bEHm7+382w5jD0tMYiYV5OM8HIZ0qmUUK0hZZl6+14MXLByiXVpMeVWsol7chYx+SwesMbKZhae5SmM0Kv4ZeY2K6YOD31VhTk2/gOjY8L8DqaoGD7bguZ0vHDsIwSBxODTJMTS4NYMH1cWe0jPxWQMoCyq2ApeQuiptFrOTzKJVKHBOk9Ot0AmTkQKZh52zGqXSuKbyfnsHC0idoWYfruQQY2uTYX9Rsh4oSleUCtn/+Rj0FVcRS0inZO0Emk3z06Dsr12VEC/2wSz8QxhoWp1Umx0iRpNLiH2xubBCzGClNiKCaME+HiVESKYoSbXfWx1HbnCNzFP4BUB2fTu3BLP8AAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Design System",
          "title": "Design System",
          "src": "/static/d2cb9ff11b4a64b4d2d449af51f260cc/d9199/mc-design-system.png",
          "srcSet": ["/static/d2cb9ff11b4a64b4d2d449af51f260cc/8ff5a/mc-design-system.png 240w", "/static/d2cb9ff11b4a64b4d2d449af51f260cc/e85cb/mc-design-system.png 480w", "/static/d2cb9ff11b4a64b4d2d449af51f260cc/d9199/mc-design-system.png 960w", "/static/d2cb9ff11b4a64b4d2d449af51f260cc/07a9c/mc-design-system.png 1440w", "/static/d2cb9ff11b4a64b4d2d449af51f260cc/29114/mc-design-system.png 1920w", "/static/d2cb9ff11b4a64b4d2d449af51f260cc/5496c/mc-design-system.png 2452w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <Text center marginBottom={designTokens.space[3]} size={designTokens.fontSizes[0]} color={({
      theme
    }) => theme.grey500} mdxType="Text">Typography documentation for light/dark modes</Text>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "64.99999999999999%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAABYlAAAWJQFJUiTwAAACrElEQVQ4y1WTy04UQRSGJ3Eh0DKCc2Gmp5kZhhkSiA6DPI0bL0FUQEKMiFHDwp0v4Bu4dGXUhRtjQpRERSEgBIgiaDRymVvfu6p/T1U3KJ38OXU59dVfdboivL4Oe+8LzD/LcKtrQHMTPgmh/EYQRY7+e0nmeZQn1rHacfmNDUTsvRVsr77Bt+XXqO58oMUbNLF+TNA30fi1iN2t95SzAFhbAP8JsB/HZXxFBDpNsiYlGDL6bp3UCFUHs+sA6nj65DGuXr6AqYkRTFy/hIlroag9PnoRk2NXsLM2h4ivb8MwLTDuQ3yM+bAdV7bFiMtkE7dujiASOYE2pR0nWxREo51QTkXR0qrIvqJEsTD3jIDGNmq1KpqNhlzoeS5q1SoMXYdpGvAYF9tg9u4kstkeFAoFpLpSKJVKGOgfQF9fH3p7i9A0DUvzLwOgYeioEoQxD5xzNJtNmJYJy7IIKHxy3J8eRzxBoGIJiUSS4DkJz+XyUprWjc/vXgRAx3bAyYnv+xJoWTZcOrbrOKFDjnsEVJQOZDIakskuZFQN3d05AmUpkiTweejQqkG3qsEdcg8GFaJp7sN2jSOH96ZvoDOpIJdXySEBsynki2lktDRtkIKqqliUDqkoXqMJ5oaF8JjsOwc1cBo7BD64PYZYWxRqJiMd5lIqetMZeZ9ig3T6EEgOmXkAbgcOfXLIyTEz9gmoHwFnZ8aQV9vR2yOOnEYhp6K/qCKfVaXDDG20OB8CRWUdxw6AflBpUSChAOjjztQo/SKtSKVSON1xBvF4nEAJcpdAR2cMsVgcn96KO9S/g3sOGMnnLnzmyj4XkTlwXYeADh49nJFVHT5fQaV8DpXBstRQpYzB8lkMDw1i9eMrAjY25HsV4qH8/yT79JbF+xXv2NwVWgnjP1k0Jlh/AUNFEanISLDDAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Design System",
          "title": "Design System",
          "src": "/static/2377edfd118ebbff7f6b3c8ff1c0766f/d9199/mc-design-system-2.png",
          "srcSet": ["/static/2377edfd118ebbff7f6b3c8ff1c0766f/8ff5a/mc-design-system-2.png 240w", "/static/2377edfd118ebbff7f6b3c8ff1c0766f/e85cb/mc-design-system-2.png 480w", "/static/2377edfd118ebbff7f6b3c8ff1c0766f/d9199/mc-design-system-2.png 960w", "/static/2377edfd118ebbff7f6b3c8ff1c0766f/07a9c/mc-design-system-2.png 1440w", "/static/2377edfd118ebbff7f6b3c8ff1c0766f/71c1d/mc-design-system-2.png 1536w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "73.75%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAACE4AAAhOAFFljFgAAADe0lEQVQ4y02US08bVxTH52u0lZovkGyrVm2ldJlVpX6MdFepmy7SbNJFRFRU0ipqUbIINISHAxiDH2Abv/CjNn5gDLYp4AcGXL8He7CNfz0zoVbvaBb3zD2v3/2fUe7evccHH37Ekx+/5+TokJE8bbVGt3uN12UhGYvQHVTxHDym3AwTC/oI+TfpD24YjW7QVyzsJei1M5StMjk5yedffMk3Xz8gHd+h1erQaraMg3uJv/BL0MrlKYVSnovLC1Ji23a8Q7vu8986Psri3Jin19NQ7t//io/v3OGzTz9h224Sp0uq1So3NyPOK2W21t9SOTun0+6iF9Ssq2JbkG9n44CdzpXY5tlPp1AePvyWmZlZ9vb2cNkWyWUPpJ2BcVBVr3DbljjKZWSn0dLy5M6tbNpmSMZjRoudTof4bpR8ykMqbEMxMqhNNK1PwL0hzIJGsKEEHel8Ql7c7jnytVUyxTVOKnEiAQeJkB3H+iKvp3/m5YtnRLxr7O5IwFarRbFYoF5vEI/uGMx04D1NE05DopEgfqeJ8I6PWMDP/OtpPLZ5KkdBrJZ3TP0yidfrJ5s9ZGtjAaXX6wrMnlFVuVTCbn5DZj9NJp0gHHDiss7TqSQMlk9/esSr6Rckk0nDOZfLjjk2mg0pxowSjcVpNBoSJEXAY6ec9Uv5Zn6bfMKjH77DYl7Bs7lMPBZG1Qa03ysFn3QS9Ltod1Ru1UPI50YJbZsxzf3B1MRjXv4+xW7QLoy2SO8fUiyVjYORoMuwDQVq/qwqSIYkd0OSyEyz2aZaP6FYC7EdeIPyd9qPxbLGkmmBaq0mtxfFbV8WYWvjdnLZDJsilXa7w7CviaChcHpsYEgcLZEuvuWk6qJYzqC4RSqqqtEbXFJrljg9KWBd/ZPTwrHc8g3X/Z5xYU7rAgeZ9FjQtVrdsB0exseJr9QeysbyLKnkruHUkLfeaEorKyQTYUM2/X7/VlLrxCJ+1Ksug0GfgfS/s20lIcr4/1L8or1oyHO7Hd3OpoeAHK5JAk17rwDdUQ+qV6gH1Ff2ICXDYBKJDQRRj8uLCsp+KoZ3a0WM1+MseWHmtC7KXLfHtqIg2BRmuj6NYZCZTyXjRH1mgj47FtMM079OoOgz6bDMCZN/xs5N0ZQOvFwqjm36AHgcJtYtqzhs64Q8GwTdy9QL8gNxmnn+/CmvZif4F6n+FnTizsflAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Design System",
          "title": "Design System",
          "src": "/static/3a72b39cb2bd19efce4d4ef2e510e3c5/d9199/mc-design-1.png",
          "srcSet": ["/static/3a72b39cb2bd19efce4d4ef2e510e3c5/8ff5a/mc-design-1.png 240w", "/static/3a72b39cb2bd19efce4d4ef2e510e3c5/e85cb/mc-design-1.png 480w", "/static/3a72b39cb2bd19efce4d4ef2e510e3c5/d9199/mc-design-1.png 960w", "/static/3a72b39cb2bd19efce4d4ef2e510e3c5/07a9c/mc-design-1.png 1440w", "/static/3a72b39cb2bd19efce4d4ef2e510e3c5/29114/mc-design-1.png 1920w", "/static/3a72b39cb2bd19efce4d4ef2e510e3c5/18d85/mc-design-1.png 2382w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <Text center marginBottom={designTokens.space[3]} size={designTokens.fontSizes[0]} color={({
      theme
    }) => theme.grey500} mdxType="Text">Example screens</Text>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "61.24999999999999%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAACE4AAAhOAFFljFgAAACbUlEQVQoz2WTW2/aQBCF84v71ipFUdIf0opIfUgUVVWjvvQhlKgh4DspJCEQMMUYAsY2vmMb6tPZJUGRaunI69ndb8/OjPeWyyh1nAD6wCh6vSGYhkMTrhtyDYcT/s00Gk3B1tKebUynOX1K4zFs2+fxvSjK0utrAScnJ8XhwQE+HB3R+BSa1kKz2cbZ2SlKpRL290sol8tQ1Ru0Wvc4//YdB4dv8e79Gxwff8avq2uEYYa9IFiltVoD6zwrojWQFUAchag3RCjSLR6ffqBnXpALHZOJCUXRwAwEfgAvNuFFI/hhyoG+F2+BbEESBcXYz2EnG/hLB6KoQJI0WPYU26eA4y4gyxoaDQmj8SM5ChHHCV13gSsCel6yBdYJ6HleEQU+4jCgPG2BoiiTkxhWcIvu/AvmtgFFviGgjOmTgWBlYJVEtMZHrVZ/AabksAHTNItnK7CsOQcqchMzy0DHPMdv4xNG0zY0tQ1BkGFORkhWPrIghOe6YAz/NdAwjCLLsmegxTcpShPDPwPMZhZpBl3v85ggKHQL9+V8RCzndeG1Q4FBijzP+QKWE+aQ5cuxXaRpjnSVYWHZ5FrjQM9b7oBxHL8GUg7pIwyD3ZVZDiVJ5bJsE3+xopKkmC/GdIjKD3NdZwdkxfkPuF7nO2CSxLRJhSCK0AZl6O5XLqX/keIid5gkyQ642WyoUCIBqW3iOE+r1V+oXl4WmiSDqVKpUK5ueHtUfl5A1RQuNq7XRT5XrVapwdukFlW4RnsuKZfU2ERNFwsPDw+D4qE7oHcf3a7OfzEW79w/4v5ZnU6fx9gci9/ddUk9rtnM4Q7/AXXoXMASZUDJAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Design System",
          "title": "Design System",
          "src": "/static/eccbde448adbc78d7949e0365fa7ab02/d9199/mc-design-2.png",
          "srcSet": ["/static/eccbde448adbc78d7949e0365fa7ab02/8ff5a/mc-design-2.png 240w", "/static/eccbde448adbc78d7949e0365fa7ab02/e85cb/mc-design-2.png 480w", "/static/eccbde448adbc78d7949e0365fa7ab02/d9199/mc-design-2.png 960w", "/static/eccbde448adbc78d7949e0365fa7ab02/07a9c/mc-design-2.png 1440w", "/static/eccbde448adbc78d7949e0365fa7ab02/29114/mc-design-2.png 1920w", "/static/eccbde448adbc78d7949e0365fa7ab02/7f6e2/mc-design-2.png 2041w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <Text center marginBottom={designTokens.space[3]} size={designTokens.fontSizes[0]} color={({
      theme
    }) => theme.grey500} mdxType="Text">Layout demonstrating spacing on a handheld mobile device</Text>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "61.24999999999999%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAACE4AAAhOAFFljFgAAACDUlEQVQoz32T7XLSQBSGewEtikIS0mj5koFiO2CnjFN1dFoCCRA+q1intKX1htQ/6u0o4ww3oj+stFAhI7CvZxeRj6KZOTkne3afnHfP7pIsK7YsyXC5JaZKClRFhax4yBR4yGuyBxLlaR5cLjdWVhxwOG78NT7Gc+M5SxTYfOHdNS8L+4PQn+1C30siraeQ2t1DPBKF2y3B6byFne0EPr7/gKPjY5zU63j35i0S8S2RU3gRYyD/8Pn9bDMUwUnlBU6rBzirvsJr8k9iW5AJuEyVHZQqwICh/fUbet9/AF0bFSMrch6POguUZIWFA0EcHdZEBYe1Gs7qp9iOPcBtLpXkVUtldFottM7PcXlxgW67jXLWug7UVjUhORK4BzNtwDBMpA0DVjaHWPS+kMwXvSzvw+71cEmgn90uhv0Byrn8ggopcEsyC/n8EyD5HAeuj4C8Gfv5IrpXV+iQtTsd/LJtFMw5yaLLow6ykJeABBsDrTng80IJYAzD4VAYvVDMLNhDAaRj8z/g8hgIzmT4EywCKrPAGcnZWcmFoqiqPxhgQDbs969LngBJsi+AjGnCNDMCauUsxKMbtL+Tpsw/FSv/7wqDa16kkjr0VApJXSf5BjbC6zyHm3R4n+48wpdGA81mU1jj02c8TjyknHP6YI+AtJeMe/4nVVVHnkyZulZcOh/TtDvCeMzHpq/eb0IjuJHufIh7AAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Design System",
          "title": "Design System",
          "src": "/static/1b0411d5de5d895f0ff5c80ee8bbabc7/d9199/mc-design-3.png",
          "srcSet": ["/static/1b0411d5de5d895f0ff5c80ee8bbabc7/8ff5a/mc-design-3.png 240w", "/static/1b0411d5de5d895f0ff5c80ee8bbabc7/e85cb/mc-design-3.png 480w", "/static/1b0411d5de5d895f0ff5c80ee8bbabc7/d9199/mc-design-3.png 960w", "/static/1b0411d5de5d895f0ff5c80ee8bbabc7/07a9c/mc-design-3.png 1440w", "/static/1b0411d5de5d895f0ff5c80ee8bbabc7/29114/mc-design-3.png 1920w", "/static/1b0411d5de5d895f0ff5c80ee8bbabc7/7f6e2/mc-design-3.png 2041w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <Section mdxType="Section">
  <Container mdxType="Container">
    <CSSGrid mdxType="CSSGrid">
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
      <Col col={6 / 8} media={{
            phone: 1
          }} mdxType="Col">
        <h5>Uncover actions and states 🕵️‍♂️</h5>
        <p>Opening a sheet every time to alter/view the state of a feature can quickly become annoying - so we wanted to expose these actions and state in the main view. By moving the action directly in the main view, we've:</p>
        <Box marginBottom={designTokens.space[3]} mdxType="Box">
          <p style={{
                marginBottom: '0'
              }}>decreased the number of taps from 2 to 1</p>
        </Box>
        <Box marginBottom={designTokens.space[3]} mdxType="Box">
          <p style={{
                marginBottom: '0'
              }}>provided a clear design affordance for each feature in a door</p>
        </Box>
        <Box marginBottom={designTokens.space[3]} mdxType="Box">
          <p style={{
                marginBottom: '0'
              }}>decreased the amount of time for a user to perform an action</p>
        </Box>
      </Col>
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
    </CSSGrid>
  </Container>
    </Section>
    <Text center marginBottom={designTokens.space[3]} size={designTokens.fontSizes[0]} color={({
      theme
    }) => theme.grey500} mdxType="Text">Actions and states in a single view</Text>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "89.58333333333334%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAYAAABb0P4QAAAACXBIWXMAACE4AAAhOAFFljFgAAACbElEQVQ4y42UTU8aURSG/YXddd1Nu+mPsbohVKJNKDBga+xCjNZVTdqkcUGIiVFRUBCFYb4/GR3u23MuDA4IsZO8ORdm5pn3fNy7Ypoe5mUYLhwnRBQJjEaYXkIA0SPgukP5TOodkcSVeRDLtgM0mx3s7PxANpvD2qcM1tcyyGSyqJS/4fz8Sn4wBU0BGcI/+CZJ12wEQYx8voiTk7/IbWzi3Yc3eP/xLVZX13H8+w9yuc15l8/ABMbRsHzoFD3vEaVSGXEcy1Sf0MIIHbnmCihKBYa+LOW0w54Go9uHR19XlG30B7dQtS7u7x8IYJMrqq1to1RUyJ03dUhxMdC6vIZ51ZLAEgF72i2C0KES+DBtFdFwiDAMUCgUYVkMcBPogpQpXVM1YdzcwQtjKF8LsFQdht+EFdwQOIBNjdAGEb5sVXDfdV53KOvYeYDf7WH38watr2GHPTh+D5HhIHR8dNoetra+o3FpkcypwyTKsTEmkl2m6FPK5fI2VL2PwB8ifhJwNR1D36eUIxSLZems0bBovCwaszGQnY7ncDIyDNV1B57/KIE+AWRnaboFR55sWilKWdZPp06fnZlT4Oxgp4HeGMjNmNkm44UcGwaqqouLCxOaNq7hs8OUZoATh4JgYgFwMHDRbtsc/xMYLAcmHaZ0XzZlGZAHmS/eMVxHFq8TYEqvOYzkS8suvqcv3Xom059FQBGGsaDREEdHR+L09FTUajWper0ujo9/iXy+IOhwEGNn0t3i4ys5wvj46rQfUK0eSO3v/yQdYr96iL29A7Rad/PHF9JAMS/+MkEFHbDkdjQj/o9gE3cv3/0HMds24YnDfxUAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Actions and states",
          "title": "Actions and states",
          "src": "/static/37fc713e2e4dc243eaf2f8eaae7cec2c/d9199/mc-layout.png",
          "srcSet": ["/static/37fc713e2e4dc243eaf2f8eaae7cec2c/8ff5a/mc-layout.png 240w", "/static/37fc713e2e4dc243eaf2f8eaae7cec2c/e85cb/mc-layout.png 480w", "/static/37fc713e2e4dc243eaf2f8eaae7cec2c/d9199/mc-layout.png 960w", "/static/37fc713e2e4dc243eaf2f8eaae7cec2c/e8814/mc-layout.png 1392w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <Section mdxType="Section">
  <Container mdxType="Container">
    <CSSGrid mdxType="CSSGrid">
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
      <Col col={6 / 8} media={{
            phone: 1
          }} mdxType="Col">
        <h5>Usability and Tap areas 👇</h5>
        <p>Through testing with prototypes, we discovered we needed to increase the size of the tap area on items with which users are able to interact - without having to alter the design of the component through the padding.</p>
        <p>Depending on the component, we decided to add padding inside the tap areas, so users were able to easily tap lower-level components and decrease the amount of "undo" flows.</p>
      </Col>
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
    </CSSGrid>
  </Container>
    </Section>
    <Text center marginBottom={designTokens.space[3]} size={designTokens.fontSizes[0]} color={({
      theme
    }) => theme.grey500} mdxType="Text">Tap area documentation</Text>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "89.58333333333334%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAYAAABb0P4QAAAACXBIWXMAACE4AAAhOAFFljFgAAADJElEQVQ4y42Uy09TQRTG+xdo4Pb94FUgEmhLGtAFsGMBxERqWzcS4xYjUizEFbgCYgwm+AgsNCZu2SDBRzRpqaCxhCCtggt1JaEtjwqFtpS28zkz3NLyMt7ky5m0d373zDnfGYlGo4NWWwCdrhBszaRSaVBRUYmamlpUVRlpPI/qajMMBhPM5hoUF+uhVmv5PlEkEyVswQCCIOPQ4pJS6AqKUFt7AZetNrS0XERdXQOsVjuampphs9lRWWnge04EKpUamEzVuNvXh/r6BpSV6FGmL0VjYyNutLejkMKlUjlkMgUX+3AGljndIWA+fanr2nUguIZgYAWfn73A/MtJ/Flfw/a8H5bmFsgUKg5mJyigMSezA6AIJxKBArtvdiC9FUGKpBFfXkFycwvsifz8BeslC6T0HQbLArJr8XciZkwk7DjOnh5sRyII/l5GKBhAeGMDsUAQG64PuEJrlgGy8igVGihoxrlSKtXZDFldem47kfQtITHrQ3LhG6JzCyC+79j54sfVtja+SSooMPTgHqY/vYPb7cHUlAculxterxcjI6NErCvLUAFnVxcFLCL8+j0i8wuI0uxSq+vYCYVgs9t5Dc+cFeCZceGkx+fzZYGsa520hnv+JcS9C0jQRmx+nMXe3Fds+xdhodYpVqqgEqSYGBtDJBTAj+/04+Ewdnd3OXB6euYIsLMTadqIWCCE5EYYsWAIqfUwoqur3It6CiyUqzH6ZAjeN/fhefsIe+n0KRnmM6Dj4E+Sc5R4IgEbBQoyJZfD6cTzx314OnwH4+MTmJx8RevpxvDww5OBafpVQgiPHBiL0QmxQUadUFBYAnmRgTZIi3I9a5KAvLx90VNmbZMLZLCMODAe50BmLR0FavQGaKl9zEYtzpUz/xVxo1NL5Rj7f4HMwGo1Hze1mmXDjK0TY87osaY4HPvAZDKJVCqFVDLFodFolF8GGaDmYFJ0OTDdkVnOl5KOjltE7McxtbZaCAUSdixxZo/pEJB1x2g0obe3DwMDAxgcHER//37s7u5BaWnZ0bvvX+L3IWs5YZ1iYtkw8bUgJxR2KDMxm1P1FziGB7qOvcjPAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Tap areas",
          "title": "Tap areas",
          "src": "/static/c6d218adcd039afb180fe357ec23f58a/d9199/mc-tap-areas.png",
          "srcSet": ["/static/c6d218adcd039afb180fe357ec23f58a/8ff5a/mc-tap-areas.png 240w", "/static/c6d218adcd039afb180fe357ec23f58a/e85cb/mc-tap-areas.png 480w", "/static/c6d218adcd039afb180fe357ec23f58a/d9199/mc-tap-areas.png 960w", "/static/c6d218adcd039afb180fe357ec23f58a/e8814/mc-tap-areas.png 1392w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <Section mdxType="Section">
  <Container mdxType="Container">
    <CSSGrid mdxType="CSSGrid">
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
      <Col col={6 / 8} media={{
            phone: 1
          }} mdxType="Col">
        <h5>Error handling 🚨</h5>
        <CardContainer mdxType="CardContainer">
          <CardBody mdxType="CardBody">
            <p style={{
                  marginBottom: '0'
                }}><i>What if there's an error connecting to Wi-Fi?</i></p>
          </CardBody>
        </CardContainer>
        <CardContainer mdxType="CardContainer">
          <CardBody mdxType="CardBody">
            <p style={{
                  marginBottom: '0'
                }}><i>What if there's a connection error to the cloud?</i></p>
          </CardBody>
        </CardContainer>
        <CardContainer mdxType="CardContainer">
          <CardBody mdxType="CardBody">
            <p style={{
                  marginBottom: '0'
                }}><i>What if a device inside the door is offline?</i></p>
          </CardBody>
        </CardContainer>
        <p>Depending on the severity of the error, users need a way to be able to see errors in realtime. When a device must interact with an IoT cloud to be able to interact with a hardware product, a slew of errors can occur - so we needed to depict each error in certain types of ways: Cards, Snackbars, Push Notifications, etc.</p>
      </Col>
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
    </CSSGrid>
  </Container>
    </Section>
    <Text center marginBottom={designTokens.space[3]} size={designTokens.fontSizes[0]} color={({
      theme
    }) => theme.grey500} mdxType="Text">Example error components</Text>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "67.91666666666667%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAACE4AAAhOAFFljFgAAACfklEQVQ4y61QTU8TURStpK0JTQlCoe10KAnEGE1A6QcrNXGj8ReotNVA/QP+Al24cuHOhTHRoK5IDLrRnXEhkYVGtsYoAsK00ylt6XvTTj/meO9Q0Nq4c5KTc99955537rhAX7lcRqlUgt22YdPZQbsN27Z7cXDfreGjwy6uTdOE2KtC2i2I9x8h3q5CtpoQ1SpqtRqazSakEJA1E7JcgVh6A7mdg7TqpBFoNBrdhjxkSglZJ8GHzxArn2CSmHv8mGPYqaVuQLx+B7m5DZPmuN9lyAU3C0YB+a2f0L7/QG59A/rGJvR8HqKyh5ZloVgoQN/RkPvyFdrmFnLf1qFrGgzD6DUUtA5fGLtFaCTSSrswmmRi1SDovkUotejcqEOn3o6eh5bPwSgWUSRYlvXbkNehBr8Ci1ZucOLVNVQfLUE8f4Xq4jLE05cdXkb1yQvUt/Ow2i00aK4za7MPYT9hz7eyBtx+ANxfBO497sbdh0BF/j1hH7Ark8kgm806SKfTmJubw7Ub15HOLiC1ME88jwzVaa4ZNxdwNZNGKpVy9AzysNmH2UUf+vr64PF4wDXjSIf/BXdvzz5kt9uN4eEAVCUC38gIbs1ewJ3zl+EPBBBVowgGQ/D5fFAjYwiRZlIdx7NLV5CcPIFAOIwx0gwMDNgcirz2Ew4NDUMdiyKqqDg7fhznCOMRFRFCgB7zer1QwgpChFNkeHHiJM5EJxAMhR1Nf3+//UdSF0Yo2dTUNGIzMcRjccwQJ+IJTE2fRphS8O+YpjqZnHX68UTS0SWIY8S0Qbeh3+/H4OAxjI4GodBaiqI4q3KP1uFVnHOYEvI980EdopRe79Fuw/+AQ8Nfv/zOCu0bMpgAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Errors",
          "title": "Errors",
          "src": "/static/2adff53b588872cdbc67f67b14445ead/d9199/mc-errors.png",
          "srcSet": ["/static/2adff53b588872cdbc67f67b14445ead/8ff5a/mc-errors.png 240w", "/static/2adff53b588872cdbc67f67b14445ead/e85cb/mc-errors.png 480w", "/static/2adff53b588872cdbc67f67b14445ead/d9199/mc-errors.png 960w", "/static/2adff53b588872cdbc67f67b14445ead/07a9c/mc-errors.png 1440w", "/static/2adff53b588872cdbc67f67b14445ead/29114/mc-errors.png 1920w", "/static/2adff53b588872cdbc67f67b14445ead/5d180/mc-errors.png 2757w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "77.5%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAYAAAAWGF8bAAAACXBIWXMAAAhOAAAITgGMMQDsAAACrUlEQVQ4y3WU3U/aUBjG/dO9WbLdTM02E3WTZIvzQr1RJ5GpWGgLqEQ3FR3CpkChLZ+FftP22TmHFnCyk5y+TZ+T33k/OxcEAUA2ecJzh3D7A7gDHa5pwaffp/Sh48AhOt2uYRLdB1PYIWaCufAFbBk2IDUBuQM0e+SG0Xff90e6pgNVBVCILrcB10PImwAj2O/7e+xubSO2toZPKyv4EoshsbuHbqs10otF7O3sYH11lemf19fxfX8fvW43YjHLPHRJKInEIW4LBSwtLWJ+fh5fNzeRyWaQTnEMGI8f4PrmBm8XFvDqzWtsbGxAEEXw6RQD0SjGIZuGAe40CUM3ML20noYsgfq+hzTHTYSBxYyiKMjlsi+BlmXiiHhYKpfRIiFqmsbypigyRIGnp5E8PoJm6OhVJXRORJimiVKpjIwozPCQiNzJMfR2B77t0CqwZFdrMgQSUkA8TBKdelSXJLZbzSbu7u6QIRfOABrgaa48n8GCsKoNWUEmI5KDPkuJQ3Ltui6zdFUqFeRISmZ6SJPrhxWPKq+q6hhIixN1V6Q3Go0poDcD6P8fmCLAqB8jW6/Xx8DIlzEwzYAe63xqR1WUQ2AQAp/rFMjzHFxfD3oDBe7QmuRQSPP4d7Vb/amQ0y90lUxULjtqG8Nu00KGbUPm9pRLQO3foq3fo6MX0bOLKD5mGZBWPJk8ZHrHeEDXKKPvlFEoC2HIduAOjUnIlkkm5XgbV9IHXNWWcVlZwY2yDP72HUSRZ8DDoy1c1t7jWvqIn7UYCuoaTn8sQhBSZOT15zn0vCHiB3H8KUmQKk08/HpCU9aQP/sRNS6+7e0zvfoko1R8hNLo4Tx3xapvWU4wGAxoO01+DiqdCjGN8/MsLi7OyMgJyBNrW9a4QCM9F+oi8nmi29a4D8kO/gItR6EOhT5wPQAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Error Types",
          "title": "Error Types",
          "src": "/static/c9bd1ddbf1103ceaad74a3b2ca2b461b/d9199/mc-error-types.png",
          "srcSet": ["/static/c9bd1ddbf1103ceaad74a3b2ca2b461b/8ff5a/mc-error-types.png 240w", "/static/c9bd1ddbf1103ceaad74a3b2ca2b461b/e85cb/mc-error-types.png 480w", "/static/c9bd1ddbf1103ceaad74a3b2ca2b461b/d9199/mc-error-types.png 960w", "/static/c9bd1ddbf1103ceaad74a3b2ca2b461b/07a9c/mc-error-types.png 1440w", "/static/c9bd1ddbf1103ceaad74a3b2ca2b461b/da952/mc-error-types.png 1872w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <Section mdxType="Section">
  <Container mdxType="Container">
    <CSSGrid mdxType="CSSGrid">
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
      <Col col={6 / 8} media={{
            phone: 1
          }} mdxType="Col">
        <h5>Automation options 🤖</h5>
        <p>We live in the age of automation, so users expect their digital and smart hardware products to be able to make their lives simpler by automatically reacting to events and doing things for their owners.</p>
        <p>In this iteration of the product, the powered & connected door is able to automate when lights turn on/off:</p>
        <Box marginBottom={designTokens.space[3]} mdxType="Box">
          <p style={{
                marginBottom: '0'
              }}>Turn my lights on when it's dark outside</p>
        </Box>
        <Box marginBottom={designTokens.space[3]} mdxType="Box">
          <p style={{
                marginBottom: '0'
              }}>Turn my lights on when there is motion outside my door</p>
        </Box>
        <Box marginBottom={designTokens.space[3]} mdxType="Box">
          <p style={{
                marginBottom: '0'
              }}>Turn my lights on when my door is open</p>
        </Box>
        <CardContainer mdxType="CardContainer">
          <CardBody mdxType="CardBody">
            <p style={{
                  marginBottom: '0'
                }}><i>How can we let users select different automation settings on their door?</i></p>
          </CardBody>
        </CardContainer>
      </Col>
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
    </CSSGrid>
  </Container>
    </Section>
    <Text center marginBottom={designTokens.space[3]} size={designTokens.fontSizes[0]} color={({
      theme
    }) => theme.grey500} mdxType="Text">From wireframe to hi-fidelity: user options for selection automations</Text>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "56.25%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAACV0lEQVQozz2S22sTQRTG+w83rYi2FHwTn6QiqOiTFKHihTyYYi8JEaxgkqaX2KRpspekUZO47W52drO3ZPd8np1teuDjDDPDj+98M0uJo2Hm/EYkBlKJowKuApJd5a4Ans69CwR9uU7PEfI6ugLmf4DZIFvz/aW56EMIB6Y1gWVZmNnaLVC5gxn9OsrFL3j29DFev9xE5XAPSqOM55tPsLGWw6ON+9jeeiXvS6BpmgjDEJ43ZaCeOWMlInXSw8nPXfwdjlkjXJsWzs9PGfACuZV7WM6tSK2uLMO/bjHQ6cO2bQRBwPLhCBPCMmCzIrvH4/TRrJcwHA4Rz+dIS9fa+Lz9BrlcDuvra1hjPXywiuDmIgNOp9M7WTz6ZGLDYnlmD/GkjdrhDkajMZ/xJFGEzmUTO/kt5PN5FAoFHBSLeLf1Fp5xno2cgtJxM6iL+XyGOI7hGAqswRHKex/xzzDkeZIkaLcbKO9/gu/78n5arWYDzqixcOgxkA89D47jyIu+H/Dr8+vNr9A8LsEWAovqXrZQ2v2AG84+yz9C/agCd5wChQ6Xc3OFJfNzXZfhHlx2E9r8FQINjdqBjGFRnXZTupbRcAypkeMFkJ+aEhb/M4qFSjwCMZC7R5G4IoQ6nVX2yfMDYpaUqlzS/s57ctwp8UREBDo7rZMYndFS+kUyKfJTpw5djsBxvcxhqKPT+IZarQJV6aDb7eDH9xI/VAHVagWnJ3W0Wuy4+BXhTSt1qFIqBsrOENZAKhba7b5C1/0jGmlVVoU4fN5XyejVaKxX5b47/kWYavQfVNEcZuJPRp4AAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Automation",
          "title": "Automation",
          "src": "/static/ee72ac576105e8c3508da8de10a8a9b5/d9199/mc-automation.png",
          "srcSet": ["/static/ee72ac576105e8c3508da8de10a8a9b5/8ff5a/mc-automation.png 240w", "/static/ee72ac576105e8c3508da8de10a8a9b5/e85cb/mc-automation.png 480w", "/static/ee72ac576105e8c3508da8de10a8a9b5/d9199/mc-automation.png 960w", "/static/ee72ac576105e8c3508da8de10a8a9b5/07a9c/mc-automation.png 1440w", "/static/ee72ac576105e8c3508da8de10a8a9b5/29114/mc-automation.png 1920w", "/static/ee72ac576105e8c3508da8de10a8a9b5/f291a/mc-automation.png 2258w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <Section mdxType="Section">
  <Container mdxType="Container">
    <CSSGrid mdxType="CSSGrid">
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
      <Col col={6 / 8} media={{
            phone: 1
          }} mdxType="Col">
        <h5>Platform Emails/Notifications 📱</h5>
        <CardContainer mdxType="CardContainer">
          <CardBody mdxType="CardBody">
            <p style={{
                  marginBottom: '0'
                }}><i>How can we communicate with door owners to let them know about events with their door?</i></p>
          </CardBody>
        </CardContainer>
        <p>We needed to build a way for users to manage which notifications they receive about their door, and how they receive them (Push or Email)? As a heavy email user, I <i>love 🤩</i> looking at designs of platform emails and what other products choose to include. In our case, we don't want to overload the user with information - simply to inform them something happened at a certain time.</p>
        <p>To help make sensible defaults for first-time users, we consulted our qualitative research interviews to see what priority users gave to certain door events, and how they expected to receive them.</p>
      </Col>
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
    </CSSGrid>
  </Container>
    </Section>
    <Text center marginBottom={designTokens.space[3]} size={designTokens.fontSizes[0]} color={({
      theme
    }) => theme.grey500} mdxType="Text">Users can modify which notifications they receive</Text>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.25%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAAsTAAALEwEAmpwYAAACy0lEQVQ4y01TW08aQRTmD9s+N2360IeaNKZNE9to0prUtNY0aBFQLoKL4O5yWwS5rXgDuakILIgLsjBfz84qdZITzp4z812GM7ZhKw+tlkavkUGf4q55BNY/4THtlTDRjmF0j+m3xL+hn6N6LGPtxxLevnqJTx/e4SDoAO5O+RmbmZibYFSBUZmKJd7g9XEFQIPiCphQf3DG94YDDpQvazg6yuKiUkUw4CMhGeD+HLZKUcT66jIW3r/G96WPSEkefnDcUREJ/MXXz/NYmH+DzfUV3FwkOcnBnhPtTgdPKyqHMLotWICRoBu5QglSLImziyr8XjcebnNoleMQBAFF9QSJpIJYQoEiEhlqXGGno+F+MEC73cWubwc1NUyAZ7DFIj6MRg9ojS22uBzGiO61c3lIzOJMRaNZRzrqp6xJCl0YDkcQD0QsLn7Bi7k5rHxbhiQ4YYuGvbglFq3bxZQxSBEBg+skamd7iEVlMKqZq1pt4FD2kuUiwkE7KdMQpX6/30ev18PowYB9fRU2KbRD7NczJWFhF9qViPOSCyklMavX6k0o8jbYMAEh8Jss9yBJEnRd5319OIDD/gs2ed/DNxuG5TkSCmDcLqJdSUFJPgdszCyLwjYG9zr1Fe7ADMMw4Nxcsyx3tT4Vp/ygFNmjO7T+FCUZnwFWa3Wy7KOsjti+G16vF6FQ6LHLMJlM4dx4BDT9Py1Z3MewlaURiSN9mJrVb1ptC5Dm1Rz0fNzDr8fCewZ4ENxikhxlhaMMy+fyzOPaYBNNZd1aiu24t5iqqkwtFpkghFgm5mMYXzKaU2Z0iywRjViOp1NGlplz8zez6Tc5FJJ+FBQ/sjEPbitJPk/mcNdLErKkJJ/woZQOkjLVekE0wHfNNHfzfG3++UlPj9g4qxlGlUG/YPT0eGBYtmpm74Hi7vR/j/KAx862XQ6263Mz19YGE/cc7B/jOqGCh6lyMQAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Notification Settings",
          "title": "Notification Settings",
          "src": "/static/b030524fb08503a62be56bdf51eb538f/d9199/mc-notification-settings.png",
          "srcSet": ["/static/b030524fb08503a62be56bdf51eb538f/8ff5a/mc-notification-settings.png 240w", "/static/b030524fb08503a62be56bdf51eb538f/e85cb/mc-notification-settings.png 480w", "/static/b030524fb08503a62be56bdf51eb538f/d9199/mc-notification-settings.png 960w", "/static/b030524fb08503a62be56bdf51eb538f/07a9c/mc-notification-settings.png 1440w", "/static/b030524fb08503a62be56bdf51eb538f/29114/mc-notification-settings.png 1920w", "/static/b030524fb08503a62be56bdf51eb538f/a7115/mc-notification-settings.png 2186w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <Text center marginBottom={designTokens.space[3]} size={designTokens.fontSizes[0]} color={({
      theme
    }) => theme.grey500} mdxType="Text">Simple email templates for door/account notifications</Text>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "51.66666666666666%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsTAAALEwEAmpwYAAACA0lEQVQoz22SXWsUMRSG5/+LV4KIN6I3oj/AYu2OYLWItLQquu3STnfne5OZSU5mMp/JHk+227IFAyeEkzcP75vE+xHKJ5moT+JKHYW89qNC+amo/Qx6P1dmllbqeJ7JN/MMXodcHq84zG7Swr9YrPxFxPwlk66OokJ+O43gqRfx9jNihzHMsCzXOAw93o+NCWhWWAgVcQFL3ButVjTb/RZGBXzxAgYfECecumQwgzRotdkMpfkVvDI/g2eT1SEyUV/mFfzZnZs2Fo1oIlO3hdls0FBvdBu3TH50wAPygmgINNYIRYx5conQpKiHwqJtCagW6wrmO6Bt2xY5Z5SovE/koEjRD71gLQ92QqN1RyKFVaVxHLaabSaCLZis59M40l5lu3Z4iGlJYWj8F+icrssAV8lvEk6PgJyAfdchZ4UNkysM4jO8Dk+Rlwn2/bgHZHdAdxeGIPPsHZ6HL3CYGsd/AO5HjqvveLZ8juerlyjam8eRb+lRLDkGUFNd11aUYAsmbNM0FgCMc0WwK6q/Zpqw0Q31jB06tGNPNRjb9f3kHC0ZHHr0rz45OgHw7rDEmJfY0brVmvwYpC8TMAHX7sK0Jl3fYZJnyDinMy2Ska31sADfu8rhrQCoSwkpVc6l2pZbU2WVhCou4ITqq5BKuF5J+8Fa5Csm82qrVakARfcC7/8B0EXtaYIIFJUAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Notification Emails",
          "title": "Notification Emails",
          "src": "/static/1c85984adcfdc3a2c066b52ec0b63d06/d9199/mc-notification-emails.png",
          "srcSet": ["/static/1c85984adcfdc3a2c066b52ec0b63d06/8ff5a/mc-notification-emails.png 240w", "/static/1c85984adcfdc3a2c066b52ec0b63d06/e85cb/mc-notification-emails.png 480w", "/static/1c85984adcfdc3a2c066b52ec0b63d06/d9199/mc-notification-emails.png 960w", "/static/1c85984adcfdc3a2c066b52ec0b63d06/07a9c/mc-notification-emails.png 1440w", "/static/1c85984adcfdc3a2c066b52ec0b63d06/29114/mc-notification-emails.png 1920w", "/static/1c85984adcfdc3a2c066b52ec0b63d06/820f7/mc-notification-emails.png 2126w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <Text center marginBottom={designTokens.space[3]} size={designTokens.fontSizes[0]} color={({
      theme
    }) => theme.grey500} mdxType="Text">Breaking down the data object into each notification type</Text>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "46.666666666666664%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAAAsTAAALEwEAmpwYAAACF0lEQVQoz21STWsTURQdoQsXuhAVFRUU4kfJIhSEItTiRylugoIggoLuVLp371LwD9S6sVCiKSmtRUv/gy4spYLBGpPMTE3nI8l8vDdvZu7xTqZGBO/jcLmP98493Hu0KIrgeR6klAiCAEopZEFE/yAPys+wHsbwgbb9fRuv5+YGeDU7i/W1Nfwv0jRFEvOPNG+WJMmguVIRIyYVp9kdadW376BpGk4dOYz9nCdKJQg/RNd20XN78PpexoCUhdv9OlqdL2i12rAsixsopAk4xxT5JiLhk1ZbrOGYtg/jF86jcOAgpktjkP0OzMZXuL8aiKXHZAFiIWG5WzDtz7DsDo/HHyi3ulv41qxQ6HxCIgVpHz6u4lLhLMYvjqJ49DjKk1fg+DZ+6A1WoyNUElGiEEZ9kO8AcY9pmCxxAWGg3lzFZnuJus4GNw9JW1p5j7HRIsrnCrh88jQeTE1BCIG+F8APBM8lhVQJAukj6HUgvR5Mowm93US79ROGbvIME8rmyQsmrVKp0qETZ+jR9Wm6PTJCMxOTtLe1IVSSkogC8jyXul2HbGeXDFMnw9DJdV1id1AmgklJW5h/g2L5IZ6ubODxzVt4fuPaXw9QzhmzSk+ErDRCyrXijbtugM6Oi5jV/7HNQOFibZmu3n9Bd54t092ZeXp57wl7IqacL2dkyxD7lCEos0akBNV31mmzVaVA7u69SQaEvwGI/HFjNKH1iQAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Notification Anatomy",
          "title": "Notification Anatomy",
          "src": "/static/c4a983e90c9d6f264ad8f771011a5f1d/d9199/mc-notification-anatomy.png",
          "srcSet": ["/static/c4a983e90c9d6f264ad8f771011a5f1d/8ff5a/mc-notification-anatomy.png 240w", "/static/c4a983e90c9d6f264ad8f771011a5f1d/e85cb/mc-notification-anatomy.png 480w", "/static/c4a983e90c9d6f264ad8f771011a5f1d/d9199/mc-notification-anatomy.png 960w", "/static/c4a983e90c9d6f264ad8f771011a5f1d/07a9c/mc-notification-anatomy.png 1440w", "/static/c4a983e90c9d6f264ad8f771011a5f1d/a9577/mc-notification-anatomy.png 1784w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <Section mdxType="Section">
  <Container mdxType="Container">
    <CSSGrid mdxType="CSSGrid">
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
      <Col col={6 / 8} media={{
            phone: 1
          }} mdxType="Col">
        <h5>Third-party products</h5>
        <p>Possibly the largest innovation of this door is the ability to power your third-party entryway devices (eg. smart locks, video doorbells):</p>
        <Box marginBottom={designTokens.space[3]} mdxType="Box">
          <p style={{
                marginBottom: '0'
              }}>Can my door/home power my video doorbell so I don't have to change out the battery?</p>
        </Box>
        <Box marginBottom={designTokens.space[3]} mdxType="Box">
          <p style={{
                marginBottom: '0'
              }}>Can my door tell me when my lock is offline?</p>
        </Box>
      </Col>
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
    </CSSGrid>
  </Container>
    </Section>
    <Text center marginBottom={designTokens.space[3]} size={designTokens.fontSizes[0]} color={({
      theme
    }) => theme.grey500} mdxType="Text">In the first iteration of the product, when a user opens the view for a device, they are prompted to view details in their proprietary app</Text>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "69.58333333333333%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAAAsTAAALEwEAmpwYAAACmElEQVQ4y32TTUwTQRTHuWuh3W0L7W4/hMgJMCEakxY4aDAGREC08tGmYEgwIUEPsEHQBNt4kB4MBk1Q/IxegIMc8EAip17sjRjwUg8m3jBaSsvWbdm/O68uNlCd5M38k5n3m/fmvSkRBAeY2WwiqqqdONXkBMdxMJut4HkLDAYj7HYR5eU2TZfBYq0gzfZs1bUQ3Mdhtwmw/+GUsEl0OMCbBJxtP4bVLz5IE8NoOX8RPt9VSJIEUXSivv4kwqEwWlpa0eDx4EpvAKdffoK7dQA2zghBdP0FElRwwVrB4fnb+2DjZ+I7lKxMuq7uBHp6ekknU2lsbydITy+ugndWQ7QLEAojZOZwuLSUTIhMP6DDP1Jx7CpbUPeAxsYm+Pv8yCkKFDmDXC5LZ17Pz8Fw5CgcTndxoMnEIRwO0+GdnRTS6TTptgttCASvkVY0+7VHEg9nH6O01Ei+RYFGI4fR0TGoqopEIoFkMkmOAX8f2n39WF3fQjS2jo8bX/H5m4xbt0Oaj+n/wKGh6wTJZDJkbNwYuYmGpjMYHpEwMjiAsVEJobv30NPdC4ulnIpWFFhWZoLfH4A+crkcrXemJPQP+vAh+h4v3rzC3PwjPHn2FN0akLWXDmTtVcImZvk35NHR0UkQlnY2mwdOhcYxPj6Bg4Nlw7I6lDIDsltYszY3n4OiVRMqsCunqAwzM7OYmJwkiCzL2kWsyiq1UiFwP0LqQw3Iwvd6GwpiUGleWFhEJBI5FGFn5yXKqmhRmLEvVVNTi2g0ilgshuV3y1haWkIw2I+urstYWVnB2toaNjc3EY/H4fF4/10U3fLvmT9g1f4tKxTHmcmR583anxfgcrlRWVkFPbtC3/03PLjq78rSYaZrfZ+BC310/RvuGlzwHOSB7QAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Third party apps",
          "title": "Third party apps",
          "src": "/static/18b5b0d0dac4e51c3dfe1a504c5b8e18/d9199/mc-third-party.png",
          "srcSet": ["/static/18b5b0d0dac4e51c3dfe1a504c5b8e18/8ff5a/mc-third-party.png 240w", "/static/18b5b0d0dac4e51c3dfe1a504c5b8e18/e85cb/mc-third-party.png 480w", "/static/18b5b0d0dac4e51c3dfe1a504c5b8e18/d9199/mc-third-party.png 960w", "/static/18b5b0d0dac4e51c3dfe1a504c5b8e18/07a9c/mc-third-party.png 1440w", "/static/18b5b0d0dac4e51c3dfe1a504c5b8e18/29114/mc-third-party.png 1920w", "/static/18b5b0d0dac4e51c3dfe1a504c5b8e18/b7861/mc-third-party.png 3665w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <Text center marginBottom={designTokens.space[3]} size={designTokens.fontSizes[0]} color={({
      theme
    }) => theme.grey500} mdxType="Text">Users may file support tickets directly from error states of their third-party devices</Text>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "60.416666666666664%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAAsTAAALEwEAmpwYAAAChUlEQVQoz42T60+SURzHeZlcXoDCEJ4HgbRAalqWFxAvm4lBW/UuKyXnWuuf6HVvW69ilYuLrq2t7E3eaCAt18vGdDWmFVO6qFxELo8C385zHi+rudbZvvud8zvb5/xuR6RndTAbjFDWaqHWMFS1WiJy1hN/V3cPtQzD4tz5VjQ1NUOlUqO1rR1nzrZAS/wnTWZYbZ1gCEtkZOvQW2+CSavHKcaARkYPs84AubwaF/odyGazmJ6dxYvJV4jH4/B4PDhWJUYwGEQ0GoXP58ebqSnEYjH6iEijYWFhjWjRN1A11dWj2XgCCoUSF50u0LWdB+Lf6dbv96NKLMX7hQXhaidObS6Xh63TDpFOp4fV3oUOcmgnsnV1o8Nmh8FwHI6BAWwX8vix/AWJj4vIFwt4OjYGiVSGUCiEQqGAb/EVbGysI5FICECG0cFiOY1GswVmUyO1PEwur4HT5QJXLCKVTiNJVCqV8MzrhVgixXwkQiPLZLaoP03u+TqSlEkj1Jo/xD/C15AH7q9yuUxtYHwcYhLh9MwMKpUKOI6j/gOgVsvib7GkUQLw0gFsd3dXqGFgnNRQhtm5OXrm0+bvU+nUYYT7oP39IdC1BywR4A7dTzz3QcVIEYm8E5qRz9GUU6nU/0dIV0Uw3oAHEpkM4fD8XoRFepdM/gPID6hCroTD2Y/lzUksrk3gU+IlYpsBPPTcg1RajfD8W/pKrpBBucKRlDeELh8JJE3h57Cvvw9Z7it+pZewth4Fh1U8evwAEokCE6/vY3nLi+iqD0s/n+DDZw8Z7Lajgbz4r2c0NuDG9VsYGbkDt/s2Rkfvwmq1o0apQk9vL4bdw7g2OIibQ0O4fPUK+Jn+DSMnN5R82zItAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Third party apps",
          "title": "Third party apps",
          "src": "/static/64598fec88b4a0488a37bf96ef2a7eff/d9199/mc-third-party-errors.png",
          "srcSet": ["/static/64598fec88b4a0488a37bf96ef2a7eff/8ff5a/mc-third-party-errors.png 240w", "/static/64598fec88b4a0488a37bf96ef2a7eff/e85cb/mc-third-party-errors.png 480w", "/static/64598fec88b4a0488a37bf96ef2a7eff/d9199/mc-third-party-errors.png 960w", "/static/64598fec88b4a0488a37bf96ef2a7eff/07a9c/mc-third-party-errors.png 1440w", "/static/64598fec88b4a0488a37bf96ef2a7eff/fa60d/mc-third-party-errors.png 1792w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <Section mdxType="Section">
  <Container mdxType="Container">
    <CSSGrid mdxType="CSSGrid">
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
      <Col col={6 / 8} media={{
            phone: 1
          }} mdxType="Col">
        <h5>What's next? 🔮</h5>
        <p>We're working on the tangiential products around the consumer/homeowner side of this mobile app and collecting research on the manufacturing/installing process of doors and electrical devices:</p>
        <Box marginBottom={designTokens.space[3]} mdxType="Box">
          <p style={{
                marginBottom: '0'
              }}>How can installers and manufacturing temporarily register to an IoT device and test the working status of a door at consumers homes' and manufacturing plants?</p>
        </Box>
        <Box marginBottom={designTokens.space[3]} mdxType="Box">
          <p style={{
                marginBottom: '0'
              }}>How can customer support representatives rectify issues a consumer may have with their product? (eg. viewing the working diagnostics of a consumer's door)</p>
        </Box>
      </Col>
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
    </CSSGrid>
  </Container>
    </Section>
    <Text center marginBottom={designTokens.space[3]} size={designTokens.fontSizes[0]} color={({
      theme
    }) => theme.grey500} mdxType="Text">A few journey maps, userflows, and service blueprints that are helping us align and map out what we need in the tertiary products:</Text>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "65.83333333333333%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAAsTAAALEwEAmpwYAAAC90lEQVQ4yy1Tz4tWVRi+qygQkmSy0pgZFGwyxQajFkGrMHCjOAguorat+hvam5ugTZuCXLgKXOhqIgcSU4gZB9JPZ5xfft/c+333u7/Ovef3fZ/ec8b38HLv+fWc53mfc5JxvYpJ+xiF3IXUKTLxCHvTf2CdBmDx+62f8daRw1j4+Bhm3n2H8yhm5+bwcPUOGjtAVq9hItZjjpt1JFO9iVIMUQzvo81XoPwY1Hs4r9DJLvYvXvoSZxZm8MXiCZz/YB5vvH4IX3+zhNY/j4ABKGQATbrRAF26DCOegbyBVh0csyPq0fc9Qiz//QdOvn0MHx2fxSfzJ3B8dg5Hjr6H23d/hcYW0moVOatMSwa0gwF638JZC2slvLfwzJCIEJo1jiElvvv+W7zGzN7/8BxmPruCQ/OL+PT8GeyMH0Z2L9OnyMYpEu8dtGyZjWOZDj2F9JFhXdecTWQ5ePEAp0+fwptnv8LihVNYOHsYS1cvY3P3XzSiQNvKuC5xRrMBJgIGkJDhEO89tNbMng9UlpcSbvz0A5IkwdK1z3H3z5uwrCyEd32sewTsA0hoEcgjTVMUZRkng+zAsCwr3txge/QIv/x2HRvba7w2KBC4d28FN368jnFRom4VkgBkreG0bIaHEAJWK2bHY4b7bYGq28Fe+h+m5T4M9lBU+yiKCkoJLK/8hcHGC5TSYVjmSIKs0XAEJVVk5ZxDPmGQqoBQI0yq5xjub3GNujhfdyN0esoMWWrfsl0KGdfPSMFz2+wyMzMMqiUPsqMBsG6ZgdhEJdLIlJXHK2RMUKKZ8Ra0UfzvuP4CrRyy/Jxr7VhyT2SMpbKoqFMV5fUGFc0OKSUPPHoVDEashrwj6syEhNonox05b3hMkclyKpsRJZLrEE6r5R7fpzW8HK+zMRM0bMaBKXWsbwjJKpqmie7XcgdK83Vj6do2mPLDGIs1JLkYUC6eUNasEgNS3aZUVTV1XUdct8hMKRW/bBhlWUpSKlKmJgbl3KWsPtjLT4/+B+wpmYwi0uo3AAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Research",
          "title": "Research",
          "src": "/static/0ec410b08b8a2a924a2477251c3b189c/d9199/mc-support-research.png",
          "srcSet": ["/static/0ec410b08b8a2a924a2477251c3b189c/8ff5a/mc-support-research.png 240w", "/static/0ec410b08b8a2a924a2477251c3b189c/e85cb/mc-support-research.png 480w", "/static/0ec410b08b8a2a924a2477251c3b189c/d9199/mc-support-research.png 960w", "/static/0ec410b08b8a2a924a2477251c3b189c/07a9c/mc-support-research.png 1440w", "/static/0ec410b08b8a2a924a2477251c3b189c/29114/mc-support-research.png 1920w", "/static/0ec410b08b8a2a924a2477251c3b189c/26162/mc-support-research.png 2020w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <Text center marginBottom={designTokens.space[3]} size={designTokens.fontSizes[0]} color={({
      theme
    }) => theme.grey500} mdxType="Text">Mobile app for installers to test the diagnostics of a door</Text>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "105.41666666666667%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAVCAYAAABG1c6oAAAACXBIWXMAAAsTAAALEwEAmpwYAAAC0UlEQVQ4y5WVW08aURDH/RheES9AwQWq9QO01bZqVRbs5+pzY3zoi2nSJlXjQw1FHvpgUlt1WbbcLGBt8dJEBVlgWWD/zjmo1fTC9iSTbHZnfjtnZv7ntHV19eDKOju7ubmGBPRa++ldL5wuFywWKyy9VgwJbu5n7euHy+XmvpexxmW80XYT2NHRhb7+AWxsfERwfQkvXz1HNruPiclJTD2dxtf0LhYXF7G29g6h8Br3ZTF/Bba3d2LQ5oASkwDoqOoq2PKJfvj8Im4u6csH8rXzmJbAre1NaFoZJbUMwwBmfSKH6rUaVLX5k0Q8hYFBU0A7InKEB9UIoBsFzMzOEDDAgZVKhX+Lx+P/A5SaQL0GrX7CgaJ/7jfgoCkgOUUVmQfpug5V/0HAacpQhKaXcX5+3txyImE2Qwe2pS3U6zVUq1WU9Z+YZkCqY1WrIJ8vcGAsFjMHtNkdSCbitzrqD8whMPeMPxusS7Qy2WzrLrOZYkP79vUb7O/tIZPJIrWbwv0HD7nJioJkMoncwQFWV1dp+Pv+PYdXavEOD0PweHDH6cLIvVH0MKWQjYyOwuF0QnB74PbeNa+UcDiMpeVlvJifRzQaxeMnE6SWKSRTSSwsLGBlZQXBYJB2Y0opdiRSKSoWFer4hNdLpKEW/YFbdU1nMmaVYockyyhTN0++fYdGnWZKYVYoFHB2dnajyzZzc6hEmnPYgHGdIZtDNtT5fP5ysBMmgZShHImQljWau+r14cAyLJVUqEWV/yqqSCbGhj4OkNO2rECnrTLo9WlDwGKxSEphwCp25E0uAlPAzzs7pGOd1FL/leGsDyXabqXMtFyHJH1qDbzacmr3tlICpBTW5UajQdasazwRha1lhkwpdPSHQus4PT1FLpfD0fERxsYfYWxsHIdHh0in0/zd+1CIXxOmlMKyZGoQBA/dHQK6uy3c2F3j9njhdnu55v+klAvRhJ5FY0m9BwAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Installer",
          "title": "Installer",
          "src": "/static/4cb057fc0ae365bbc6ad48f74ec4e4a5/d9199/mc-installer.png",
          "srcSet": ["/static/4cb057fc0ae365bbc6ad48f74ec4e4a5/8ff5a/mc-installer.png 240w", "/static/4cb057fc0ae365bbc6ad48f74ec4e4a5/e85cb/mc-installer.png 480w", "/static/4cb057fc0ae365bbc6ad48f74ec4e4a5/d9199/mc-installer.png 960w", "/static/4cb057fc0ae365bbc6ad48f74ec4e4a5/07a9c/mc-installer.png 1440w", "/static/4cb057fc0ae365bbc6ad48f74ec4e4a5/29114/mc-installer.png 1920w", "/static/4cb057fc0ae365bbc6ad48f74ec4e4a5/aae30/mc-installer.png 2080w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <Text center marginBottom={designTokens.space[3]} size={designTokens.fontSizes[0]} color={({
      theme
    }) => theme.grey500} mdxType="Text">Web app for customer support representatives to work support tickets and help consumers who may have issues?</Text>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "65.41666666666667%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAAsTAAALEwEAmpwYAAACMklEQVQ4y5VT3WoTQRgNmNa2Kk2bxDaa2ppKMSEBaZsqCNVX8EKwtEnzI2rrq3nnqwiCIIgXapJ2s5vN7vzs7B6/mWST2iLiwGHPzM6cOeebmcRSdh3LtzeQyqzj1lKecBc3U/+HVOae0dBaCUNIbCW/iQcPK9gqbaNYrqJU2UNRg3ixMka5Ou2Xp1jNb01EE5nVAmbmszioNWDbDpgHuEMPFnHbceEMhgb2YMrjfp/+CxHg4KiN5FwaWdIiwfuYXVjBy6MX+Nb/iC/dD/h13oHrMsgggFQhAqWgwghCBhCBAJf6XwguJJzIRb31DteuLyOb2xw5nCWHr2qH6NifcOZ+xo/OGfr9ATjntIgTtxBFEYaeSyn6CKMQjHHadADd6s0Lgtpmcj6N4+YJ4iakhOd5UOROSGG4boz5JOIa7vmMShILnlwQzBVM/kZ7JKhCRc7EWFCBcTYRkSTOGDO8azn4+v0nHF+iRg6Tc5cEm+1TMzEMKQ7VRrsUUlGtpIlGiWmToYlv5tCmuiRXI192SK58HmDIKDZXdOIkYlkmvhY0NaQD0jX0fe/vgq3X76c1DCLwMfRJayHdAuJxZKki+EIYftw6/fOUZ+hQ6o23iMLQ1M6i+3Vuu/TV981Br9ejcR8WOe12uyNOc3rWgEQVDutvxjUsjF6KvuVrGyVUnzzH7uNn2NmbQvfNmME+Hu08pfF909+m7y6tydPayUuJ3/Jieg0Liznc+CfuXBnTa+O3/Bsoy6hYMe9maQAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Support",
          "title": "Support",
          "src": "/static/b3db73b7b84d166746aeaf18a4d294e4/d9199/mc-support.png",
          "srcSet": ["/static/b3db73b7b84d166746aeaf18a4d294e4/8ff5a/mc-support.png 240w", "/static/b3db73b7b84d166746aeaf18a4d294e4/e85cb/mc-support.png 480w", "/static/b3db73b7b84d166746aeaf18a4d294e4/d9199/mc-support.png 960w", "/static/b3db73b7b84d166746aeaf18a4d294e4/07a9c/mc-support.png 1440w", "/static/b3db73b7b84d166746aeaf18a4d294e4/29114/mc-support.png 1920w", "/static/b3db73b7b84d166746aeaf18a4d294e4/ca98b/mc-support.png 1968w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <Section name="Next" mdxType="Section">
  <Container mdxType="Container">
    <CSSGrid mdxType="CSSGrid">
      <>
      <Col col={1 / 8} media={{
              phone: 1
            }} mdxType="Col"></Col>
      <Col col={6 / 8} media={{
              phone: 1
            }} mdxType="Col">
        <Box bg={({
                theme
              }) => theme.greenTransparent} center mdxType="Box">
          <div style={{
                  marginBottom: designTokens.space[3],
                  width: '100%',
                  maxWidth: '104px',
                  margin: 'auto'
                }}>
            <ImageWrap srcName="masonite-example-icon" mdxType="ImageWrap" />
          </div>
          <h5>Upcoming</h5>
          <p>This project is still a work in progress, so check back for more updates</p>
        </Box>
      </Col>
      <Col col={1 / 8} media={{
              phone: 1
            }} mdxType="Col"></Col>
      </>
    </CSSGrid>
  </Container>
    </Section>
    <CallToAction mdxType="CallToAction" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      